import React from 'react'
import { Link } from 'gatsby'
import { Icon } from 'antd'
import logo from '../img/logo.svg'

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer" style={{padding: '1rem'}}>
        <div className="has-text-centered">
          <Link to="/">
            <img
              src={logo}
              alt="The Casting Agency"
              style={{ width: '8em', height: '10em' }}
            />
          </Link>
        </div>
        <div className="has-text-centered">
          <div className="container">
            <div className="columns">
              <div className="column is-4">
                <section className="menu">
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/">
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/about">
                        About
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/talent">
                        Talent
                      </Link>
                    </li>
                      {/* <li>
                        <Link className="navbar-item" to="/blog">
                          Latest stories
                        </Link>
                      </li> */}
                  </ul>
                </section>
              </div>
              <div className="column is-4">
                <section>
                  <ul className="menu-list">
                    <li>
                      <Link className="navbar-item" to="/bookings">
                        New booking
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/contact">
                        Contact
                      </Link>
                    </li>
                    <li>
                      <Link className="navbar-item" to="/legal">
                        Terms
                      </Link>
                    </li>
                  </ul>
                </section>
              </div>
              <div className="column is-4 social">
                <a title="facebook" href="https://www.facebook.com/TheCastingAgency/">
                  <Icon type="facebook" theme="filled" style={{color: '#1E2126', fontSize: '16px'}} />
                </a>
                <a title="twitter" href="https://twitter.com/CastingAgencyTV">
                  <Icon type="twitter" style={{color: '#1E2126', fontSize: '16px'}} />
                </a>
                <a title="linkedin" href="/">
                  <Icon type="linkedin" theme="filled" style={{color: '#1E2126', fontSize: '16px'}} />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="container" style={{padding: '8rem 1rem 4rem', textAlign: 'center', fontSize: 14, opacity: 0.7}}>
          <div className="columns">
            <div className="column is-12">
              &copy; {new Date().getFullYear()} The Casting Agency is part of The Agency-UK.com. Company No. 05480769
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
